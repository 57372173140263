import order from '@/api/order/order'
import router from '@/router'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'


export default createStore({
  state: {
    "user_id": "",
    sidebarVisible: '',
    sidebarUnfoldable: false,
    token: '',
    searchData: {
      member: '',
      advertise: '',
      theme: '',
      order: '',
      goods: '',
      alarm: '',
      importerApprove: '',
      rank: '',
    }
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },

    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },

    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },

    // 회원관리
    searchDataMember(state, payload) {
      state.searchData.member = payload
    },

    // 발주목록 관리
    searchDataOrder(state, payload) {
      state.searchData.order = payload
    },

    // 상품목록 관리
    searchDataGoods(state, payload) {
      state.searchData.goods = payload
    },

    // 알림관리
    searchDataAlarm(state, payload) {
      state.searchData.alarm = payload
    },

    // 수입사 승인관리
    searchDataImporterApprove(state, payload) {
      state.searchData.importerApprove = payload
    },

    // 점주 유저 랭킹
    searchDataSellerRank(state, payload) {
      state.searchData.rank = payload
    },

    // 수입사 유저 랭킹
    searchDataImporterRank(state, payload) {
      state.searchData.rank = payload
    },

    // 와인 랭킹
    searchDataWineRank(state, payload) {
      state.searchData.rank = payload
    },

    loginToken(state, payload) {
      state.token = payload
      // router.go()
      router.push('/manage-ad')
    },
    loginCheck(state) {
      if (!state.token) {
        router.push('/login')
      }
    },
    userId(state, payload){
      state.user_id = payload;
    }
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      paths: ['SellerAccountInfo'],
    }),
  ],
  strict: false,
})